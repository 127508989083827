<template>
  <div class="bulkImportPage">
    <el-breadcrumb separator="/" style="margin:10px 0">
      <el-breadcrumb-item :to="{ path: '/platform/deviceManage/list' }">设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>导入设备</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="topView" style="margin:0 0 30px 0;display: block">
      <!-- 功能按钮组 -->
      <div class="funcs">
        
        <!-- 顶部按钮组-->
        <div class="head">
          <el-button size="small" @click="$router.back()" icon="el-icon-arrow-left">返回</el-button>
          <el-button type="primary" size="small" @click="save" icon="el-icon-check">提交资料</el-button>
          <el-button type="primary" icon="el-icon-wallet" size="small" @click="showDriverInfo = true">导入设备表格</el-button>
        </div> 
      </div>
      
      <el-table 
        :data="tableData" 
        v-loading="loading"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }">
        <!-- <el-table-column type="selection" align="center" width="55"/> -->
        <el-table-column type="index" align="center" label="序号" width="50"/>
        <el-table-column align="center" prop="IMEI" label="设备编号"/>
        <el-table-column align="center" prop="EquipType" label="设备类型"/>
        <el-table-column align="center" prop="VehicleNo" label="绑定车牌号"/>
        <el-table-column align="center" prop="VehicleNoType" label="牌照类型"/>
        <el-table-column align="center" prop="VehicleType" label="车辆类型"/>
        <el-table-column align="center" prop="DriverName" label="绑定司机"/>
        <el-table-column align="center" prop="DriverPhone" label="司机手机号码"/>
        <el-table-column align="center" prop="ErrorMsg" label="导入错误信息"/>
      </el-table>
    </div>
    <!-- 导入车辆信息dialog -->
    <el-dialog class="driverInfo" :visible.sync="showDriverInfo" width="540px">
      <span slot="title">导入车辆信息</span>
      <div class="body">
        <el-button type="primary" size="medium" @click="VehicleExcel">上传Excel表格数据</el-button>
        <el-alert style="margin: 10px"
          type="warning"
          :closable="false">
          <span slot="title">
            <span>1.请按照excel字段规则填写； 2.单次导入不超过1000条数据；</span>
          </span>
        </el-alert>
        <div class="download" @click="download">点击下载(设备信息批量导入Excel模板)</div>
        <input ref="driverInfo" type="file" style="display:none" @change="importDriverInfo">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deviceExcel, saveEquipInfoList } from '@/api/platform/deviceManage/index';
export default {
    data() {
        return{
          loading: false, // 表格加载
          tableData: [], //表格数据
          showDriverInfo: false,
        }
    },

    methods:{
      //保存
      save() {
        saveEquipInfoList(this.tableData).then(res => {
          this.$router.go(-1)
        })
      },
      //上传Excel
      VehicleExcel() {
        this.$refs.driverInfo.click()
        this.$refs.driverInfo.value = ''
      },
      //导入车辆信息
      importDriverInfo(e) {
        this.showDriverInfo = true
        this.loading = true
        const file = e.srcElement.files[0] || e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        deviceExcel(formData).then(res => {
          res.data.forEach((item, index) => {
            item.index = index
          })
          this.loading = false
          this.tableData = res.data
          this.showDriverInfo = false
          //解决连续上传同一个文件不触发change事件
          this.$refs.driverInfo.value = ''
        })
      },
      //下载Excel模板
      download() {
        window.open('https://res.gts56.com/fzkj/bsc/Device/%E8%AE%BE%E5%A4%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx')
      },
    },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/style/variable.scss';
.bulkImportPage {
  .funcs {
    margin-bottom: 10px;
    .head{
      border-bottom: 1px solid #f0f0f0;
      padding-bottom: 10px;
    }
  }
  .el-table {
    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .driverInfo {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .el-alert{
      color: #f11010;
    }
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .hint {
        margin: 20px 0;
        font-size: 13px;
      }
      .download {
        color: $primary;
        cursor: pointer;
      }
    }
  }
  .driverPhoto {
    .body {
      .uploadArea {
        .upload {
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
          margin-top: 10px;
          border-radius: 10px;
          background-color: #fbfdff;
          border: 1px dashed #c0ccda;
          cursor: pointer;
        }
        .bulkImportList {
          max-height: 400px;
          overflow-y: scroll;
          display: flex;
          flex-wrap: wrap;
          .item {
            position: relative;
            margin: 0 10px 10px 0;
            display: flex;
            flex-direction: column;
            img {
              width: 100px;
              height: 100px;
              border-radius: 10px;
            }
            span {
              padding-top: 5px;
              font-size: 12px;
              color: $textGray2;
            }
            i {
              position: absolute;
              top: 0px;
              right: 0px;
              color: rgb(219, 9, 9);
              cursor: pointer;
              padding: 10px;
            }
          }
        }
      }
      .hint {
        margin: 20px 0;
        font-size: 13px;
      }
    }
  }
  .editDriverForm {
    .content {
      display: flex;
      flex-wrap: wrap;
      .el-upload__tip {
        color: $textGray2;
      }
    }
  }
}
</style>